import React, { useReducer } from "react";
import AnnouncementContext from "./announcementContext";
import announcementReducer from "./announcementReducer";
import axios from "axios";
import { toast } from "react-toastify";

import {
  ADD_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  ANNOUNCEMENT_ERROR,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_READ,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_UNREAD,
  SET_CURRENT_ANNOUNCEMENT,
  CLEAR_CURRENT_ANNOUNCEMENT,
} from "../types";

const AnnouncementState = (props) => {
  const initialState = {
    unreadAnnouncements: [],
    announcements: [],
    currentAnnouncement: null,
  };
  const [state, dispatch] = useReducer(announcementReducer, initialState);
  // add announcement
  const addAnnouncement = async (announcement) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/announcement", announcement, config);
      dispatch({ type: ADD_ANNOUNCEMENT, payload: res.data });
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      const res = await axios.delete(`/api/announcement/delete/${id}`);
      dispatch({ type: DELETE_ANNOUNCEMENT, payload: id });
      toast.success(`Announcement is deleted.`);
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
    }
  };
  // get unread
  const getAllAnnouncements = async () => {
    try {
      const res = await axios.get("/api/announcement/all");
      dispatch({ type: GET_ANNOUNCEMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
    }
  };
  const getAllShopAnnouncements = async () => {
    try {
      const res = await axios.get("/api/announcement/shop");
      dispatch({ type: GET_ANNOUNCEMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
    }
  };

  // get unread
  const getUnreadAnnouncements = async () => {
    try {
      const res = await axios.get("/api/announcement/unread");
      dispatch({ type: GET_ANNOUNCEMENTS_UNREAD, payload: res.data });
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
    }
  };

  // update Product
  const updateAnnouncement = async (announcement) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/announcement/update/${announcement._id}`, announcement, config);
      dispatch({ type: UPDATE_ANNOUNCEMENT, payload: announcement });
      toast.success(`Announcement is updated.`);
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
      toast.error(`Comment is not updated`);
    }
  };

  const updateAnnouncmentRead = async (announcement) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/announcement/update/read/${announcement._id}`, announcement, config);
      dispatch({ type: UPDATE_ANNOUNCEMENT_READ, payload: announcement._id });
      // toast.success(`Announcement is updated.`);
    } catch (err) {
      dispatch({ type: ANNOUNCEMENT_ERROR });
      toast.error(`Announcement is not updated`);
    }
  };

  const setCurrentAnnouncement = (announcement) => {
    dispatch({ type: SET_CURRENT_ANNOUNCEMENT, payload: announcement });
  };
  const clearCurrentAnnoucement = () => {
    dispatch({ type: CLEAR_CURRENT_ANNOUNCEMENT });
  };

  return (
    <AnnouncementContext.Provider
      value={{
        announcements: state.announcements,
        currentAnnouncement: state.currentAnnouncement,
        unreadAnnouncements: state.unreadAnnouncements,
        addAnnouncement,
        deleteAnnouncement,
        getAllAnnouncements,
        getUnreadAnnouncements,
        updateAnnouncement,
        setCurrentAnnouncement,
        clearCurrentAnnoucement,
        updateAnnouncmentRead,
        getAllShopAnnouncements,
      }}
    >
      {props.children}
    </AnnouncementContext.Provider>
  );
};

export default AnnouncementState;
