import {
  ADD_ANNOUNCEMENT,
  CLEAR_CURRENT_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_UNREAD,
  SET_CURRENT_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_READ,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [...state.announcements, action.payload],
      };
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
        loadingAnnouncements: false,
      };
    case GET_ANNOUNCEMENTS_UNREAD:
      return {
        ...state,
        unreadAnnouncements: action.payload,
        loadingAnnouncements: false,
      };
    case UPDATE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.map((announcement) => {
          if (announcement._id === action.payload._id) {
            return action.payload;
          } else {
            return announcement;
          }
        }),
        loadingAnnouncements: false,
      };
    case UPDATE_ANNOUNCEMENT_READ:
      return {
        ...state,
        unreadAnnouncements: state.unreadAnnouncements.filter((announcement) => announcement._id !== action.payload),
      };
    case SET_CURRENT_ANNOUNCEMENT:
      return {
        ...state,
        currentAnnouncement: action.payload,
      };
    case CLEAR_CURRENT_ANNOUNCEMENT:
      return {
        ...state,
        currentAnnouncement: null,
      };
    case DELETE_ANNOUNCEMENT:
      return { ...state, announcements: state.announcements.filter((announcement) => announcement._id !== action.payload) };
    default:
      return state;
  }
};
