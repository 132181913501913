import React, { Fragment, useEffect, useContext } from "react";
import TrimColorColumns from "./TrimColorColumns";

const AllTrimColors = ({ trimColors, setSelectedTrimColor, selectedTrimColor }) => {
  const theTitle = "Trim Colors";

  return (
    <TrimColorColumns
      trimColors={trimColors}
      setSelectedTrimColor={setSelectedTrimColor}
      selectedTrimColor={selectedTrimColor}
      theTitle={theTitle}
    />
  );
};

export default AllTrimColors;
