import { SET_ALERT, REMOVE_ALERT, GET_INVOICE_ALERT, REMOVE_INVOICE_ALERT } from "../types";

export default (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case REMOVE_ALERT:
      return { ...state, alerts: state.alerts?.filter((alert) => alert.id !== action.payload) };
    case REMOVE_INVOICE_ALERT:
      return { ...state, invoiceAlerts: state.invoiceAlerts?.filter((alert) => alert.id !== action.payload) };
    case GET_INVOICE_ALERT:
      return {
        ...state,
        invoiceAlerts: action.payload,
      };
    default:
      return state;
  }
};
