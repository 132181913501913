import {
  ADD_COMPONENT_CATEGORY,
  DELETE_COMPONENT_CATEGORY,
  CLEAR_COMPONENT_CATEGORYS,
  SET_CURRENT_COMPONENT_CATEGORY,
  CLEAR_CURRENT_COMPONENT_CATEGORY,
  UPDATE_COMPONENT_CATEGORY,
  FILTER_COMPONENT_CATEGORYS,
  CLEAR_FILTER_COMPONENT_CATEGORY,
  COMPONENT_CATEGORY_ERROR,
  GET_COMPONENT_CATEGORYS,
  SET_LOADING_COMPONENT_CATEGORY,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_COMPONENT_CATEGORYS:
      return {
        ...state,
        componentCategorys: action.payload,
      };
    case ADD_COMPONENT_CATEGORY:
      return {
        ...state,
        componentCategory: action.payload,
        loading: false,
      };
    case UPDATE_COMPONENT_CATEGORY:
      const updatedComponentCategorys = state.componentCategorys.map((componentCategory) =>
        componentCategory._id === action.payload._id ? action.payload : componentCategory
      );
      return {
        ...state,
        componentCategorys: updatedComponentCategorys,
        loading: false,
      };
    case DELETE_COMPONENT_CATEGORY:
      return {
        ...state,
        componentCategorys: state.componentCategorys.filter((componentCategory) => componentCategory._id !== action.payload),
        loading: false,
      };
    case CLEAR_COMPONENT_CATEGORYS:
      return {
        ...state,
        componentCategorys: null,
        filteredComponentCategorys: null,
        error: null,
        currentComponentCategory: null,
      };
    case SET_CURRENT_COMPONENT_CATEGORY:
      return {
        ...state,
        currentComponentCategory: action.payload,
      };
    case CLEAR_CURRENT_COMPONENT_CATEGORY:
      return {
        ...state,
        currentComponentCategory: null,
      };
    case FILTER_COMPONENT_CATEGORYS:
      return {
        ...state,
        filteredComponentCategorys: state.componentCategorys.filter((componentCategory) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return componentCategory.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_COMPONENT_CATEGORY:
      return {
        ...state,
        filteredComponentCategorys: null,
      };
    case COMPONENT_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_COMPONENT_CATEGORY:
      return {
        ...state,
        loadingComponentCategory: action.payload,
      };

    default:
      return state;
  }
};
