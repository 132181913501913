import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import AnnouncementForm from "./AnnouncementForm";

const AnnouncementModal = ({ handleClose, handleShow, show, storMorSpecific }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body>
          <div className="row">
            <AnnouncementForm handleClose={handleClose} storMorSpecific={storMorSpecific} />
            <div>
              <button className="btn btn-outline-danger float-end mt-4" type="button" onClick={handleClose}>
                Exit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AnnouncementModal;
