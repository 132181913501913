import {
  ADD_ITEM,
  DELETE_ITEM,
  CLEAR_ITEMS,
  SET_CURRENT_ITEM,
  CLEAR_CURRENT_ITEM,
  UPDATE_ITEM,
  FILTER_ITEMS,
  CLEAR_FILTER_ITEM,
  ITEM_ERROR,
  GET_ITEMS,
  SET_LOADING_ITEM,
  GET_STANDARD_ITEMS,
  GET_ALL_ITEMS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case GET_ALL_ITEMS:
      return {
        ...state,
        allItems: action.payload,
      };
    case GET_STANDARD_ITEMS:
      return {
        ...state,
        standardItems: action.payload,
      };
    case ADD_ITEM:
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case UPDATE_ITEM:
      const updatedItems = state.items && state.items.map((item) => (item._id === action.payload._id ? action.payload : item));
      return {
        ...state,
        items: updatedItems,
        loading: false,
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
        loading: false,
      };
    case CLEAR_ITEMS:
      return {
        ...state,
        items: null,
        allItems: null,
        filteredItems: null,
        error: null,
        currentItem: null,
        loadingItem: false,
      };
    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };
    case CLEAR_CURRENT_ITEM:
      return {
        ...state,
        currentItem: null,
      };
    case FILTER_ITEMS:
      return {
        ...state,
        filteredItems: state.items.filter((item) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return item.name.match(regex);
        }),
      };
    case CLEAR_FILTER_ITEM:
      return {
        ...state,
        filteredItems: null,
      };
    case ITEM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_ITEM:
      return {
        ...state,
        loadingItem: action.payload,
      };

    default:
      return state;
  }
};
