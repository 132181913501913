import React from "react";

const PermissionDenied = () => {
  return (
    <div>
      <h2>Permission Denied</h2>
    </div>
  );
};

export default PermissionDenied;
