import {
  SET_SETTINGS,
  UPDATE_SETTINGS,
  SETTINGS_ERROR,
  GET_SETTINGS,
  GET_BASE_STYLES,
  GET_SHOP_SETTINGS,
  GET_COMPANY_SETTINGS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
      };
    case GET_SHOP_SETTINGS:
      return {
        ...state,
        shopSettings: action.payload,
        loading: false,
      };
    case GET_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: action.payload,
        loading: false,
      };
    case GET_BASE_STYLES:
      return {
        ...state,
        baseStyles: action.payload,
        loading: false,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: null,
        settings: action.payload,
        loading: false,
      };

    case SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
