import React, { useContext, useEffect } from "react";
import AnnouncementContext from "../../../../context/announcements/announcementContext";
import { List } from "antd";

const ShopAnnouncements = () => {
  const announcementContext = useContext(AnnouncementContext);
  const { announcements, getAllAnnouncements } = announcementContext;

  useEffect(() => {
    getAllAnnouncements();
  }, []);

  return (
    <List
      className="mt-4"
      size="small"
      bordered
      dataSource={announcements}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={`${item.madeBy.name} @  ${new Date(item.createdAt).toLocaleDateString()}`}
            description={item.msg}
          />
        </List.Item>
      )}
    />
  );
};

export default ShopAnnouncements;
