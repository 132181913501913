import React, { useReducer } from "react";
import ItemCategoryContext from "./itemCategoryContext";
import itemCategoryReducer from "./itemCategoryReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ITEM_CATEGORY,
  DELETE_ITEM_CATEGORY,
  CLEAR_ITEM_CATEGORYS,
  SET_CURRENT_ITEM_CATEGORY,
  CLEAR_CURRENT_ITEM_CATEGORY,
  UPDATE_ITEM_CATEGORY,
  ITEM_CATEGORY_ERROR,
  GET_ITEM_CATEGORYS,
  SET_LOADING_ITEM_CATEGORY,
} from "../types";

const ItemCategoryState = (props) => {
  const initialState = {
    itemCategorys: null,
    currentItemCategory: null,
    loadingItemCategory: false,
    error: null,
  };

  const [state, dispatch] = useReducer(itemCategoryReducer, initialState);

  // get all ItemCategorys
  const getItemCategorys = async () => {
    try {
      const res = await axios.get(`/api/itemcategory/`);
      dispatch({ type: GET_ITEM_CATEGORYS, payload: res.data });
    } catch (err) {
      dispatch({ type: ITEM_CATEGORY_ERROR });
    }
  };

  const addItemCategory = async (itemCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/itemcategory/", itemCategory, config);
      dispatch({ type: ADD_ITEM_CATEGORY, payload: res.data });
    } catch (err) {
      dispatch({ type: ITEM_CATEGORY_ERROR });
    }
  };

  const deleteItemCategory = async (id) => {
    try {
      const res = await axios.delete(`/api/itemcategory/delete/${id}`);
      dispatch({ type: DELETE_ITEM_CATEGORY, payload: id });
    } catch (err) {
      dispatch({ type: ITEM_CATEGORY_ERROR });
    }
  };

  // update ItemCategory
  const updateItemCategory = async (itemCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/itemcategory/update/${itemCategory._id}`, itemCategory, config);
      dispatch({ type: UPDATE_ITEM_CATEGORY, payload: itemCategory });
      toast.success(`ItemCategory is updated`);
    } catch (err) {
      dispatch({ type: ITEM_CATEGORY_ERROR });
    }
  };

  const clearItemCategorys = () => {
    dispatch({ type: CLEAR_ITEM_CATEGORYS });
  };

  const setCurrentItemCategory = (itemCategory) => {
    dispatch({ type: SET_CURRENT_ITEM_CATEGORY, payload: itemCategory });
  };

  const clearCurrentItemCategory = () => {
    dispatch({ type: CLEAR_CURRENT_ITEM_CATEGORY });
  };

  const setLoadingItemCategory = (bool) => {
    dispatch({ type: SET_LOADING_ITEM_CATEGORY, payload: bool });
  };

  return (
    <ItemCategoryContext.Provider
      value={{
        itemCategorys: state.itemCategorys,
        currentItemCategory: state.currentItemCategory,
        loadingItemCategory: state.loadingItemCategory,
        error: state.error,
        getItemCategorys,
        addItemCategory,
        deleteItemCategory,
        setCurrentItemCategory,
        clearCurrentItemCategory,
        updateItemCategory,
        clearItemCategorys,
        setLoadingItemCategory,
      }}
    >
      {props.children}
    </ItemCategoryContext.Provider>
  );
};

export default ItemCategoryState;
