import React, { useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import AnnouncementContext from "../../../../context/announcements/announcementContext";
import { Input, Checkbox, TextArea } from "antd";

const initialState = {
  msg: "",
};

const AnnouncementForm = ({ handleClose, storMorSpecific }) => {
  const announcementContext = useContext(AnnouncementContext);
  const { addAnnouncement, currentAnnouncement, updateAnnouncement, clearCurrentAnnoucement } = announcementContext;
  useEffect(() => {
    if (currentAnnouncement) {
      setValue("msg", currentAnnouncement.msg);
      setValue("_id", currentAnnouncement._id);
      setValue("madeBy", currentAnnouncement.madeBy);
      setValue("createdAt", currentAnnouncement.createdAt);
      setValue("storMorSpecific", storMorSpecific);
    } else {
      reset(initialState);
      setValue("storMorSpecific", storMorSpecific);
    }
  }, []);

  // destructure
  const { msg } = initialState;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    initialState,
  });

  const onSubmit = (values) => {
    if (currentAnnouncement) {
      updateAnnouncement(values);
      clearCurrentAnnoucement();
      handleClose();
    } else {
      addAnnouncement(values);
      handleClose();
    }
  };

  const setMsg = (m) => {
    setValue("msg", m);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-container">
      <h4 className="form-title">Announcement</h4>
      <div className="form-group">
        <Controller
          render={({ field, name }) => (
            <textarea
              rows={6}
              className="form-control"
              name={name}
              value={msg}
              {...field}
              onChange={(e) => {
                const msg = e.target.value;
                setMsg(msg);
              }}
            />
          )}
          control={control}
          name={"msg"}
        />
      </div>
      <div className="form-group">
        <Controller
          control={control}
          name="storMorSpecific"
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <label className="mylabel">
              <input type="checkbox" checked={value} onChange={onChange} onBlur={onBlur} name={name} ref={ref} />
              Stor-Mor
            </label>
          )}
        />
      </div>
      <button className="btn btn-primary btn-block">Save</button>
    </form>
  );
};

export default AnnouncementForm;
