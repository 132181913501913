import {
  ADD_ITEM_CATEGORY,
  DELETE_ITEM_CATEGORY,
  CLEAR_ITEM_CATEGORYS,
  SET_CURRENT_ITEM_CATEGORY,
  CLEAR_CURRENT_ITEM_CATEGORY,
  UPDATE_ITEM_CATEGORY,
  ITEM_CATEGORY_ERROR,
  GET_ITEM_CATEGORYS,
  SET_LOADING_ITEM_CATEGORY,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ITEM_CATEGORYS:
      return {
        ...state,
        itemCategorys: action.payload,
      };
    case ADD_ITEM_CATEGORY:
      return {
        ...state,
        itemCategory: action.payload,
        loading: false,
      };
    case UPDATE_ITEM_CATEGORY:
      const updatedItemCategorys = state.itemCategorys.map((itemCategory) =>
        itemCategory._id === action.payload._id ? action.payload : itemCategory
      );
      return {
        ...state,
        itemCategorys: updatedItemCategorys,
        loading: false,
      };
    case DELETE_ITEM_CATEGORY:
      return {
        ...state,
        itemCategorys: state.itemCategorys.filter((itemCategory) => itemCategory._id !== action.payload),
        loading: false,
      };
    case CLEAR_ITEM_CATEGORYS:
      return {
        ...state,
        itemCategorys: null,
        filteredItemCategorys: null,
        error: null,
        currentItemCategory: null,
      };
    case SET_CURRENT_ITEM_CATEGORY:
      return {
        ...state,
        currentItemCategory: action.payload,
      };
    case CLEAR_CURRENT_ITEM_CATEGORY:
      return {
        ...state,
        currentItemCategory: null,
      };
    case ITEM_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_ITEM_CATEGORY:
      return {
        ...state,
        loadingitemCategory: action.payload,
      };

    default:
      return state;
  }
};
