import React, { Fragment, useContext, useEffect, useState } from "react";
import AnnouncementContext from "../../../../context/announcements/announcementContext";
import { List } from "antd";
import AnnouncementModal from "./AnnouncementModal";
import AuthContext from "../../../../context/auth/authContext";

const Announcements = () => {
  const announcementContext = useContext(AnnouncementContext);
  const { announcements, deleteAnnouncement, getAllShopAnnouncements, setCurrentAnnouncement, clearCurrentAnnoucement } =
    announcementContext;
  const [storMorAnnouncement, setStorMorAnnouncement] = useState(false);
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    getAllShopAnnouncements();
  }, []);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    clearCurrentAnnoucement();
  };

  const handleAddAnnouncement = () => {
    handleShow();
  };

  const handleDelete = (m) => {
    deleteAnnouncement(m._id);
  };

  const handleEdit = (m) => {
    setCurrentAnnouncement(m);
    handleShow();
  };

  const filteredAnnouncements = announcements && announcements.filter((announcement) => announcement.storMorSpecific === false);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-2">
          {user && user.role === "Office Admin" && (
            <button className="mt-2 btn btn-primary " onClick={() => handleAddAnnouncement()}>
              Add Shop Announcement
            </button>
          )}
        </div>
        <AnnouncementModal handleClose={handleClose} handleShow={handleShow} show={show} storMorSpecific={storMorAnnouncement} />
      </div>

      <List
        className="mt-4"
        size="small"
        bordered
        dataSource={filteredAnnouncements}
        renderItem={(item) => (
          <List.Item
            actions={[
              user && user.role === "Office Admin" && (
                <button className="btn btn-xs btn-outline-info ms-4" type="button" onClick={(e) => handleEdit(item)}>
                  Edit
                </button>
              ),
              user && user.role === "Office Admin" && (
                <button className="btn btn-xs btn-outline-danger ms-4" type="button" onClick={(e) => handleDelete(item)}>
                  Delete
                </button>
              ),
            ]}
          >
            <List.Item.Meta
              title={`${item.madeBy.name} @  ${new Date(item.createdAt).toLocaleDateString()}`}
              description={item.msg}
            />
          </List.Item>
        )}
      />
    </Fragment>
  );
};

export default Announcements;
