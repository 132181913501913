import React, { useContext, useRef, useEffect } from "react";
import ItemContext from "./itemContext";

const ItemFilter = () => {
  const itemContext = useContext(ItemContext);
  const { filterItems, clearFilterItem, filteredItem } = itemContext;

  const text = useRef("");

  useEffect(() => {
    if (filteredItem === null) {
      text.current.value = "";
    }
  });

  const onChange = (e) => {
    if (text.current.value !== "") {
      filterItems(e.target.value);
    } else {
      clearFilterItem();
    }
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <form className="pb-2">
          <input className="form-control" type="text" ref={text} placeholder="Search Items By Name..." onChange={onChange} />
        </form>
      </div>
    </div>
  );
};

export default ItemFilter;
